import request from "@/utils/request"

export function sendEmailCode(email){
    return request({
        url: '/v1_0/email/code/' + email,
        method: 'get',
    })
}

export function login(data){
    return request({
        url: '/v1_0/authorizations',
        method: 'post',
        data: data
    })
}



export function refreshToken(){
    return request({
        url: 'v1_0/authorizations',
        method: 'put'
    })
}


export function getUserInfo(){
    return request({
        url: '/v1_0/user/info',
        method: 'get'
    })
}


export function modifyUserInfo(data){
    return request({
        url: '/v1_0/user/info',
        method: 'put',
        data
    })
}


export function getPrivacyArticles(){
    return request({
        url: '/v1_0/privacy/articles',
        method: 'get'
    })
}

export function getIdeaArticles(){
    return request({
        url: '/v1_0/privacy/idea/article',
        method: 'get'
    })
}

export function getDairyArticles(){
    return request({
        url: '/v1_0/privacy/dairy/article',
        method: 'get'
    })
}

export function deleteArticle(params){
    return request({
        url: '/v1_0/privacy/articles',
        method: 'delete',
        params,
    })
}

export function getArticle(params){
    return request({
        url: '/v1_0/privacy/article',
        method: 'get',
        params,
    })
}

export function putArticle(data, params){
    return request({
        url: '/v1_0/privacy/article',
        method: 'put',
        data,
        params,
    })
}


