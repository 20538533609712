import axios from "axios";
import store from "@/store"
import router from "@/router";
import { Message } from "element-ui";
import { getToken, getRefreshToken, setToken } from "./auth";


const service = axios.create({
    // baseURL: 'http://47.95.199.94:5000',
    baseURL: 'https://l2mf.com',
    // baseURL: 'http://192.168.0.122:5000',
    // baseURL: 'http://127.0.0.1:5000',
    timeout: 5000
})

// 配置拦截器
// 请求拦截器
service.interceptors.request.use(
    config => {
        if(store.getters.token){
            config.headers["Authorization"] = "Bearer " + getToken()
        }else if(store.getters.refreshToken){
            config.headers["Authorization"] = "Bearer " + getRefreshToken()
        }
        return config
    },
    error => {
        console.log(error)
        return Promise.reject(error)
    }
)

// 响应拦截器
service.interceptors.response.use(
    response => {

        return response
    },
    async error => {

        const { response } = error

        if(response.status === 401){

            // 用户未认证，令牌失效,将refreshtoken赋值到token
            // 从state中获取refreshtoken值
            const token = store.getters.refreshToken
            // 赋值给state中的token
            store.commit("user/SET_TOKEN", token)
            // 赋值给cookie
            setToken(token)
            // 调接口刷新令牌,等待新结果
            const refresh = await store.dispatch('user/refreshtoken')

            const result = await service(error.config)

            return result


            // // 用户未认证，令牌失效,将refreshtoken赋值到token
            // // 从state中获取refreshtoken值
            // const token = store.getters.refreshToken
            // // 赋值给state中的token
            // store.commit("user/SET_TOKEN", token)
            // // 赋值给cookie
            // setToken(token)
            // // 调接口刷新令牌,等待新结果
            // store.dispatch('user/refreshtoken')
            // // 重新发送原始请求
            // .then((res)=>{
            //     return service(error.config)
            // })
            // .catch((err)=>{console.log(err)})

        }else if(response.status === 403){
            // 直接跳转到登录页面
            // 移除所有token
            store.dispatch('user/logout').then(()=>{

                    Message.error({
                    message: '用户认证已失效，请重新登录',
                    center: true,
                    duration: 3000
                  })
                router.push('/login')
            }).catch(err=>console.log(err))
            
        }


        }
)




export default service