import { getToken, getRefreshToken, setToken, setRefreshToken, getUsername, setUsername ,removeUsername, removeToken, removeRefreshToken } from "@/utils/auth"
import { login, refreshToken, modifyUserInfo } from "@/api/user"



const getDefaultState = () => {
    return {
        token: getToken(),
        refreshToken: getRefreshToken(),
        name: getUsername(),

    }
}


const state = getDefaultState()

// 修改state
const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },

    SET_REFRESH_TOKEN: (state, token) => {
        state.refreshToken = token
    },

    SET_NAME: (state, name) => {
        state.name = name
    }
}

const actions = {
    syncModifyUserInfo({ commit }, data){
        return new Promise((resolve, reject)=>{
            modifyUserInfo(data)
            .then((res)=>{
                // 设置存储的name
                const { data } = res
                if(data.message === "更新成功"){
                    commit('SET_NAME', data.name)
                    setUsername(data.name)
                }
                resolve(res)
                
            })
            .catch((err)=>{
                reject(err)
            })
        })
    },


    login({commit}, data){
        return new Promise((resolve, reject) => {
            login(data)
            .then(response => {
                const { data } =response
                commit('SET_TOKEN', data.data.token)
                setToken(data.data.token)
                commit('SET_REFRESH_TOKEN', data.data.refresh_token)
                setRefreshToken(data.data.refresh_token)
                commit('SET_NAME', data.data.name)
                setUsername(data.data.name)
                resolve()
                
            })
            .catch(error => {
                reject(error)
            })
        })
    },


    logout({commit}){

        return new Promise((resolve, reject) => {
            commit('SET_TOKEN', '')
            commit('SET_REFRESH_TOKEN', '')
            commit('SET_NAME', '')

            removeUsername()
            removeToken()
            removeRefreshToken()

            resolve()
        })
    },

    refreshtoken({ commit }){
        // 调用接口，设置新token
        return new Promise((resolve, reject) => {
            refreshToken()
            .then((res)=>{
                // 如果没有res就是403错误
                if(res){
                    // console.log(res)
                    const { data } = res
                    commit('SET_TOKEN', data.data.token)
                    setToken(data.data.token)
                    resolve()
                }else{
                    reject()
                }
                
            })
            .catch((err)=>{
                reject(err)
            })
        })

    }

    
}




export default {
    namespaced: true,
    state,
    mutations,
    actions
}