<template>
  <div id="app-all">
    <ShortCut></ShortCut>
    <Main></Main>
    <!-- <Footer></Footer> -->
  </div>
</template>



<script>
import ShortCut from './components/ShortCut.vue'
import Main from './components/Main.vue'
import AppMain from './components/AppMain.vue'
import Footer from './components/Footer.vue'

export default {
  name: 'LayOut',
  components: {
    ShortCut,
    Main,
    AppMain,
    Footer
  }

}
</script>

<style scoped>

#app-all{
  display: flex;
  flex-direction: column;

}

</style>