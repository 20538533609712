import router from "./router";
import { getToken } from "./utils/auth";


const whiteList = ['/', '/main/home', '/404', '/login']


router.beforeEach((to, from, next) => {
    
    const hasToken = getToken()

    if (hasToken){
        if(to.path === '/login'){
            next('/')
        }else{
            next()
        }
    }else{
        if (whiteList.indexOf(to.path) !== -1){
            next()
        }else{
            next(`/login?redirect=${to.path}`)
        }
    }


})