<template>
  <div id="app">
    <router-view></router-view>
    
  </div>
</template>

<script>
export default {

}
</script>

<style scoped>
#app{
  background-color: #f8f9fe;
}

</style>