<template>
  <div>
    <el-col :xs="0" :md="24" :lg="24" :xl="24" hidden-md-and-down>
  <!-- 版权区,固定在最下面 -->
  <div class="footer">
    

      <div class="left">
          <ul>
              <li><a href="javascript:;">关于我们</a></li>
              <li><a href="javascript:;">帮助中心</a></li>
  
          </ul>
      </div>
  
      <div class="right">
          <ul>
              <li><a href="javascript:;">冀ICP备2023038551号</a></li>
          </ul>
      </div>
  
  </div>
    </el-col>

  </div>
  </template>
  
  <script>
  export default {
  
  }
  </script>
  
  <style scoped>
  
  
  /* 底部版区 */
  .footer {
      width: 100%;
      height: 32px;
      background-color: rgb(255, 255, 255);
      border-top: rgb(237, 237, 237);
      padding: 0 30px;
  
      font-size: 12px;
      font-weight: 400;
      line-height: 32px;
      color: rgb(85, 85, 85);
      position: fixed;
      bottom: 0;
      border-top: 1px solid rgba(127, 127, 127, .3);
  
  }
  
  .footer .left {
      float: left;
  }
  
  .footer .left li,
  .footer .right li {
      display: inline-block;
      margin-right: 20px;
  }
  
  .footer .left li:last-child,
  .footer .right li:last-child {
      margin-right: 0;
  }
  
  .footer .left li a:hover,
  .footer .right li a:hover {
      color: rgb(255, 115, 0);
  }
  
  .footer .right {
      float: right;
  }
  </style>