import Vue from "vue";
import VueRouter from "vue-router";
import LayOut from '@/layout'

import NotFound404 from "@/views/NotFound404.vue";




Vue.use(VueRouter)


// 对push方法进行重写，解决重定向问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err);
}

const routes = [
    {
        path: '/404',
        component: NotFound404
    },

    {

        // 直接找router-view下的孩子
        path: '/',
        component: LayOut,
        redirect: '/main/home',
        children: [{
            path: 'userinfo',
            // name: 'UserInfo',
            component: () => import('@/views/userinfo/index.vue'),
            children: [{
                path: '',
                name: 'Basic',
                component: () => import('@/views/userinfo/inner/Basic.vue')
            },{
                path: 'other',
                name: "Other",
                component: () => import('@/views/userinfo/inner/Other.vue')
            },{
                path: 'pass',
                name: 'ChangPassword',
                component: () => import('@/views/userinfo/inner/ChangPassword.vue')
            }]
            
        },,{
            path: 'privacy',
            component: () => import('@/views/privacy/index.vue'),
            children: [
                {
                    path: '',
                    name: 'All',
                    component: () => import('@/views/privacy/inner/All.vue')
                },
                {
                    path: 'modify',
                    name: 'Modify',
                    component: () => import('@/views/privacy/inner/Modify.vue')
                },
                {
                    path: 'idea',
                    name: 'Idea',
                    component: () => import('@/views/privacy/inner/Idea.vue')
                },
                {
                    path: 'dairy',
                    name: 'Dairy',
                    component: () => import('@/views/privacy/inner/Dairy.vue')
                },
            ]
            
        },{

            path: 'main',
            component: () => import('@/layout/components/AppMain.vue'),
            children: [
                {
                    path: 'home',
                    component: () => import('@/views/home/index'),
                    children: [
                        {
                            path:'',
                            name: 'PaperList',
                            component: () => import('@/views/home/PaperList.vue')
                        },
                        {
                            path: 'paper',
                            name: 'Paper',
                            component: () => import('@/views/home/Paper.vue')
                        },
                        {
                            path: 'today',
                            name: "Today",
                            component: () => import('@/views/home/Today.vue')
                        }
                    ]
    
                },
                
    
            ]
        }]
        
        
    },


    {
        path: '/login',
        component: () => import('@/views/login/index')

    },



    {
        path: '*',
        redirect: '/404'
    }
]


const router = new VueRouter({
    mode: 'hash',
    routes,
})


export default router