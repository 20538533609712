import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'


import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import 'element-ui/lib/theme-chalk/display.css';


import '@/styles/base.css'
import '@/styles/iconfont/iconfont.css'

import '@/permission'

Vue.config.productionTip = false

Vue.use(ElementUI)





new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
