<template>
  <div class="appmain">

    <el-col :xs="24" :md="24" :lg="20" :xl="18">

      <div class="navbar">
        <router-link class="routerlink" :class="{active:isAll}" style="margin-left: 30px;" to="/main/home" @click.native="clickAll">全部</router-link>
        <router-link class="routerlink" to="/main/home/today" :class="{active:isToday}" @click.native="clickToday">今日</router-link>
        <router-link class="routerlink" to="">后续功能开发，敬请期待</router-link>
      </div>

        <router-view></router-view>
    </el-col>
    
  </div>
</template>

<script>

export default {
  data(){
    return{
      isAll: true,
      isToday: false
    }
  },
  methods:{
    clickAll(){
      this.isAll = true
      this.isToday = false
    },
    clickToday(){
      this.isToday = true
      this.isAll = false
    }
  }


}
</script>

<style scoped>
.appmain{
    display: flex;
    justify-content: center;
    margin-top: 10px;
    min-height: 100vh;
}

.navbar{
  
  height: 40px; 
  background-color: rgb(255, 255, 255); 
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: rgb(197, 197, 197) 0px 0px 7px 1px;

}

.routerlink{
    float: left;
    line-height: 40px;
    margin-left: 20px;
    padding: 0px 20px;
    border-radius: 10px;
}

.routerlink:hover{
  cursor: pointer;
  background-color: rgba(0, 255, 255, 1);
}

.active{
  background-color: rgb(240, 248, 255);
  box-shadow: 0 0 7px 1px #c5c5c5;
}

</style>